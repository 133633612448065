import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Pulse from 'react-reveal/Pulse';

import Button from "../../../common/Button";

import * as S from "./styles";
import ImgIot from "../ImgIot";

const CallIot = ({ title, number, content, button, icon, t, id, paragraph }) => {

  return (
    <S.CallIotContainer>
      <Row type="flex" justify="space-between" align="middle" id={id}>
      <Col lg={1} md={11} sm={11} xs={24}>
        <Pulse>
          <h3>{t(number)}</h3>
        </Pulse>
   </Col>
        <Col lg={12} md={12} sm={11} xs={24}>
          <Pulse>
            <S.ContentWrapper>
              <h4>{t(title)}</h4>
              <S.Content>{t(content)}</S.Content>
              <S.Content>{t(paragraph)}</S.Content>
              <S.ButtonWrapper>
                {button &&
                  typeof button === "object" &&
                  button.map((item, id) => {
                    return (
                      <Button
                        key={id}
                        color={item.color}
                        width="true"
                        onClick={() => window.open('https://calendly.com/cpm-/cita-manager?month=2021-12')}
                      >
                        {t(item.title)}
                      </Button>
                    );
                  })}
              </S.ButtonWrapper>
            </S.ContentWrapper>
          </Pulse>
     </Col>
        <Col lg={11} md={11} sm={12} xs={24}>
            <ImgIot
                src={icon}
                className="chart"
                width="100%"
                height="100%"
              />
     </Col>
      </Row>
    </S.CallIotContainer>
  );
};

export default withTranslation()(CallIot);