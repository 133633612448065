import React from 'react';
import './index.css';
import { Row, Col } from 'antd';
import Pulse from 'react-reveal/Pulse';

function CardComponent({ title, title1, title2, imageUrl, imageUrl1, imageUrl2, content, content1, content2 }) {
  return (
    <Pulse>
    <div className="cards-container">
      <Row>
      <Col className="card-container" xs={24} sm={12} md={8}>
          <div className="image-container">
            <img src={imageUrl1} alt="" />
          </div>
          <div className="card-content">
            <hr/>
            <div className="card-title">
              <h5>{title1}</h5>
            </div>
            <div className="card-body">
              <p>{content1}</p>
            </div>
          </div>
        </Col>
        <Col className="card-container" xs={24} sm={12} md={8}>
          <div className="image-container">
            <img src={imageUrl} alt="" />
          </div>
          <div className="card-content">
            <hr/>
            <div className="card-title">
              <h5>{title}</h5>
            </div>
            <div className="card-body">
              <p>{content}</p>
            </div>
          </div>
        </Col>
        <Col className="card-container" xs={24} sm={12} md={8}>
          <div className="image-container">
            <img src={imageUrl2} alt="" />
          </div>
          <div className="card-content">
            <hr/>
            <div className="card-title">
              <h5>{title2}</h5>
            </div>
            <div className="card-body">
              <p>{content2}</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    </Pulse>
  );
}

export default CardComponent;
