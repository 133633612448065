import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Slide from "react-reveal/Slide";

import * as S from "./styles";

const RightBlock = ({ number, content, t, id }) => {

  return (
    <S.RightBlockContainer>
      <Row type="flex" justify="space-between" align="middle" id={id}>
      <Col lg={1} md={11} sm={11} xs={24}>
        <Slide left>
          <S.ContentNumber><h3>{t(number)}</h3></S.ContentNumber>
        </Slide>
      </Col>
        <Col lg={11} md={11} sm={11} xs={24}>
          <Slide left>
            <S.ContentWrapper>
              <S.Content><h5>{t(content)}</h5></S.Content>
            </S.ContentWrapper>
          </Slide>
        </Col>
          <Col lg={11} md={11} sm={12} xs={24}>
        </Col>
      </Row>
    </S.RightBlockContainer>
  );
};

export default withTranslation()(RightBlock);