import styled from 'styled-components';

export const MiddleBlock = styled.section`
  background: #EBEBEB;
  position: relative;
  padding: 3rem 1rem 7rem 1rem;
  text-align: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 3rem 1rem 7rem 1rem;
  }

  @media screen and (max-width: 414px) {
    padding 3rem 1rem 2rem 1rem;
  }
`;

export const Content = styled.p`
  padding: 0 0 0.75rem;

  @media only screen and (max-width: 414px) {
    padding: 0 2rem 2rem 2rem;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 570px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
