import styled from 'styled-components';

export const RightBlockContainer = styled.section`
  position: relative;
  padding: 1rem 10rem 1rem 10rem;

  @media only screen and (max-width: 768px) {
    padding: 0.5rem 0 0.5rem;
  }

  @media only screen and (max-width: 414px) {
    padding: 3rem 3.5rem 0 3.5rem;
    text-align: center;
  }
`;

export const Content = styled.p`
  margin: 1.5rem 0 10rem 0;
`;

export const ContentNumber = styled.p`
  text-align: left;
  
  @media only screen and (max-width: 414px) {
    margin: 0 2rem 0 -2rem;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  margin: 9rem 0;
  max-width: 540px;

  @media only screen and (max-width: 480px) {
    margin: -5rem 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 400px;
`;