import styled from 'styled-components';

export const CardContainer = styled.div`
  position: relative;
  padding: 0 5rem 0 0;
  background: #000000;

  @media screen and (max-width: 414px) {
    padding: 0 0 0 0;
  }
`;