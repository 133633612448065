import { lazy } from "react";

import IntroContent from "../../content/IntroContent.json";
import MiddleBlockContent from "../../content/MiddleBlockContent.json";
import MiddleBlockDownContent from "../../content/MiddleBlockDownContent.json";
import FirstContent from "../../content/FirstContent.json";
import SecondContent from "../../content/SecondContent.json";
import ThirdContent from "../../content/ThirdContent.json";
import CallContent from "../../content/CallContent.json";
import CardIot from "../../components/CardIot";
import CardContent from "../../content/CardContent.json";
import CardsContent from "../../content/CardsContent.json";
import CallIot from "../../components/CallIot";
import CardComponent from "../../components/CardComponent";
import ContentBlock from "../../components/ContentBlock";
import MiddleBlock from "../../components/MiddleBlock";
import MiddleBlockDown from "../../components/MiddleBlockDown";
import ScrollToTop from "../../common/ScrollToTop";

const HeroBlock = lazy(() => import("../../components/HeroBlock"));
const Container = lazy(() => import("../../common/Container"));

const Home = () => {
  return (
    <Container>
      <ScrollToTop />
      <HeroBlock
        type="right"
        first="true"
        title={IntroContent.title}
        subtitle={IntroContent.subtitle}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="hero.svg"
        id="intro"
      />
      <MiddleBlock
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        button={MiddleBlockContent.button}
      />
      <ContentBlock
        type="right"
        number={FirstContent.number}
        content={FirstContent.text}
        id="mission"
      />
      <ContentBlock
        type="left"
        number={SecondContent.number}
        content={SecondContent.text}
        id="about"
      />
      <ContentBlock
        type="bottom"
        number={ThirdContent.number}
        content={ThirdContent.text}
        id="product"
      />
      <MiddleBlockDown
        title={MiddleBlockDownContent.title}
        content={MiddleBlockDownContent.text}
      />
      <CardIot
        type="right"
        title={CardContent.title}
        content={CardContent.text}
        button={CardContent.button}
        icon="hero.svg"
      />
      <CardComponent
        title={CardsContent.title}
        imageUrl="https://cpmanager.io/img/svg/confianza_n.svg"
        content={CardsContent.content}
        title1={CardsContent.title1}
        content1={CardsContent.content1}
        imageUrl1="https://cpmanager.io/img/svg/transp_n.svg"
        title2={CardsContent.title2}
        content2={CardsContent.content2}
        imageUrl2="https://cpmanager.io/img/svg/prof_n.svg"
      />
      <CallIot
        type="right"
        first="true"
        title={CallContent.title}
        content={CallContent.text}
        button={CallContent.button}
        icon="call.svg"
        id="intro"
      />
    </Container>
  );
};

export default Home;
