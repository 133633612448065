import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Slide from "react-reveal/Slide";

import * as S from "./styles";

const LeftContentBlock = ({ number, content, t, id }) => {
  return (
    <S.LeftContentBlock>
      <Row type="flex" justify="space-between" align="middle" id={id}>
        <Col lg={11} md={11} sm={12} xs={24}></Col>
          <Col lg={11} md={11} sm={11} xs={24}>
            <Slide right>
             <S.Content><h5>{t(content)}</h5></S.Content>
            </Slide>
          </Col>  
        <Col lg={1} md={11} sm={11} xs={24}>
          <Slide right>
            <S.ContentWrapper>
              <S.ContentNumber><h3>{t(number)}</h3></S.ContentNumber>
            </S.ContentWrapper>
          </Slide>
        </Col>
      </Row>
    </S.LeftContentBlock>
  );
};

export default withTranslation()(LeftContentBlock);