import { lazy } from "react";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Pulse from 'react-reveal/Pulse';

import * as S from "./styles";

const ButtonWhite = lazy(() => import("../../common/ButtonWhite"));

const MiddleBlock = ({ title, content, button, t }) => {

  return (
    <S.MiddleBlock>
      <Row type="flex" justify="center" align="middle">
        <Pulse>
          <S.ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <h6>{t(title)}</h6>
              <S.Content>{t(content)}</S.Content>
              {button ? (
                <ButtonWhite
                  name="submit"
                  type="submit"
                  onClick={() => window.open('https://calendly.com/cpm-/cita-manager?month=2021-10')}
                >
                  {t(button)}
                </ButtonWhite>
              ) : (
                ""
              )}
            </Col>
          </S.ContentWrapper>
        </Pulse>
      </Row>
    </S.MiddleBlock>
  );
};

export default withTranslation()(MiddleBlock);