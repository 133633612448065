import { lazy } from "react";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Pulse from 'react-reveal/Pulse';

import * as S from "./styles";

const ButtonWhite = lazy(() => import("../../common/ButtonWhite"));

const MiddleBlockDown = ({ title, content, button, t }) => {
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <S.MiddleBlockDown>
      <Row type="flex" justify="center" align="middle">
        <Pulse>
          <S.ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <h5>{t(title)}</h5>
              <S.Content>{t(content)}</S.Content>
              {button ? (
                <ButtonWhite
                  name="submit"
                  type="submit"
                  onClick={() => scrollTo("mission")}
                >
                  {t(button)}
                </ButtonWhite>
              ) : (
                ""
              )}
            </Col>
          </S.ContentWrapper>
        </Pulse>
      </Row>
    </S.MiddleBlockDown>
  );
};

export default withTranslation()(MiddleBlockDown);
