import styled from 'styled-components';

export const CallIotContainer = styled.section`
  position: relative;
  padding: 1rem 1rem -0.5rem 1rem;
  background: #000000;

  @media screen and (max-width: 768px) {
    padding: 3rem 1rem 7rem 1rem;
  }

  @media screen and (max-width: 414px) {
    padding 1rem 2.5rem 0 2.5rem;
  }
`;

export const Content = styled.p`
  color: #EBEBEB;
  margin: 1.5rem 0 2rem 0;
`;

export const ContentWrapper = styled.div`
  position: relative;
  max-width: 540px;

  @media only screen and (max-width: 480px) {
    margin: 2rem 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 400px;
`;