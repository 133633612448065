import React from "react";
import { withTranslation } from "react-i18next";
import * as S from "./styles";
import icon from '../../../call.svg';
import Slide from "react-reveal/Slide";

const ImgIot = () => {
  return (
    <Slide bottom>
      <S.CardContainer>
        <img src={icon} alt="100%" width="100%" />
      </S.CardContainer>
    </Slide>
    
  );
}

export default withTranslation()(ImgIot);