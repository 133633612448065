import React from "react";
import { withTranslation } from "react-i18next";
import * as S from "./styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const data = [
  {
    name: "Ene",
    BITCOIN: 14.3,
    CPM: 22,
    amt: 2400
  },
  {
    name: "Feb",
    BITCOIN: 50.8,
    CPM: 49.3,
    amt: 2210
  },
  {
    name: "Mar",
    BITCOIN: 80.8,
    CPM: 79.3,
    amt: 2290
  },
  {
    name: "Abr",
    BITCOIN: 79.1,
    CPM: 102.3,
    amt: 2000
  },
  {
    name: "May",
    BITCOIN: 43.6,
    CPM: 74.1,
    amt: 2181
  },
  {
    name: "Jun",
    BITCOIN: 37.6,
    CPM: 57.2,
    amt: 2500
  },
  {
    name: "Jul",
    BITCOIN: 56,
    CPM: 71.8,
    amt: 2100
  },
  {
    name: "Ago",
    BITCOIN: 69.5,
    CPM: 97.7,
    amt: 2100
  },
  {
    name: "Sep",
    BITCOIN: 62.5,
    CPM: 91.1,
    amt: 2100
  },
  {
    name: "Oct",
    BITCOIN: 102.5,
    CPM: 121.9,
    amt: 2100
  }
];

const ChartIot = () => {
  return (
    <S.CardContainer>
     <ResponsiveContainer width="95%" height={400}>
    <LineChart width={500} height={300} data={data}>
      <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
      <YAxis unit="%"/>
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="CPM"
        stroke="#00FF33"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="BITCOIN" stroke="#FFFFFF" />
    </LineChart>
    </ResponsiveContainer>
    </S.CardContainer>
  );
}

export default withTranslation()(ChartIot);